import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faDiagnoses, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const BenefitsSection = styled.section`
  padding: 6rem 0;
  background: linear-gradient(135deg, var(--light-shade) 0%, var(--white) 100%);
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

const BenefitsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
`;

const BenefitCard = styled.div`
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  width: 300px;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: var(--main-brand-color);
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
`;

const BenefitTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--dark-accent);
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const BenefitDescription = styled.p`
  font-size: 1.1rem;
  color: var(--dark-shade);
  line-height: 1.6;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--dark-accent);
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: var(--main-brand-color);
    margin: 1rem auto 0;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const Benefits = () => {
  const benefitsRef = useRef(null);

  useEffect(() => {
    const cards = benefitsRef.current.querySelectorAll('.benefit-card');
    // gsap.from(cards, {
    //   opacity: 0,
    //   y: 50,
    //   stagger: 0.2,
    //   duration: 0.8,
    //   ease: 'power3.out',
    //   scrollTrigger: {
    //     trigger: benefitsRef.current,
    //     start: 'top 80%',
    //   },
    // });
  }, []);

  return (
    <BenefitsSection ref={benefitsRef}>
      <SectionTitle>Beneficios del diagnóstico</SectionTitle>
      <BenefitsContainer>
        <BenefitCard className="benefit-card">
          <IconWrapper>
            <FontAwesomeIcon icon={faRocket} />
          </IconWrapper>
          <BenefitTitle>Automatización de procesos</BenefitTitle>
          <BenefitDescription>
            Identificamos oportunidades clave para automatizar y optimizar tus procesos, impulsando la eficiencia operativa.
          </BenefitDescription>
        </BenefitCard>
        <BenefitCard className="benefit-card">
          <IconWrapper>
            <FontAwesomeIcon icon={faDiagnoses} />
          </IconWrapper>
          <BenefitTitle>Diagnóstico tecnológico</BenefitTitle>
          <BenefitDescription>
            Evaluamos exhaustivamente tus herramientas tecnológicas actuales, identificando áreas de mejora y potencial de crecimiento.
          </BenefitDescription>
        </BenefitCard>
        <BenefitCard className="benefit-card">
          <IconWrapper>
            <FontAwesomeIcon icon={faChartLine} />
          </IconWrapper>
          <BenefitTitle>Consultoría de negocio</BenefitTitle>
          <BenefitDescription>
            Proporcionamos recomendaciones estratégicas y prácticas para aumentar tus ventas y mejorar la eficiencia general del negocio.
          </BenefitDescription>
        </BenefitCard>
      </BenefitsContainer>
    </BenefitsSection>
  );
};

export default Benefits;
