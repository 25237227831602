import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  line-height: 1.6;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const Subtitle = styled.h2`
  margin-top: 20px;
  color: #333;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
`;

const List = styled.ul`
  margin-left: 20px;
  list-style-type: disc;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>Política de Privacidad del Sitio Web www.visionary-ops.com</Title>

      <Subtitle>I. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</Subtitle>
      <Paragraph>
        Respetando lo establecido en la legislación vigente, VisionaryOps (en adelante, también Sitio Web) se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos.
      </Paragraph>

      <Subtitle>Leyes que incorpora esta política de privacidad</Subtitle>
      <Paragraph>
        Esta política de privacidad está adaptada a la normativa española y europea vigente en materia de protección de datos personales en internet. En concreto, la misma respeta las siguientes normas:
      </Paragraph>
      <List>
        <li>El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016.</li>
        <li>La Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD).</li>
        <li>El Real Decreto 1720/2007, de 21 de diciembre, que aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999.</li>
        <li>La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE).</li>
      </List>

      <Subtitle>Identidad del responsable del tratamiento de los datos personales</Subtitle>
      <Paragraph>El responsable del tratamiento de los datos personales recogidos en VisionaryOps es: Costin Soava, con NIF: X6834946J (en adelante, Responsable del tratamiento). Sus datos de contacto son los siguientes:</Paragraph>
      <Paragraph><strong>Dirección:</strong> [Agregar dirección]</Paragraph>
      <Paragraph><strong>Teléfono de contacto:</strong> [Agregar teléfono]</Paragraph>
      <Paragraph><strong>Email de contacto:</strong> <a href="mailto:hivisionaryops@gmail.com">hivisionaryops@gmail.com</a></Paragraph>

      <Subtitle>Registro de Datos de Carácter Personal</Subtitle>
      <Paragraph>
        En cumplimiento de lo establecido en el RGPD y la LOPD-GDD, le informamos que los datos personales recabados por VisionaryOps, mediante los formularios extendidos en sus páginas quedarán incorporados y serán tratados en nuestro fichero con el fin de poder facilitar, agilizar y cumplir los compromisos establecidos entre VisionaryOps y el Usuario o el mantenimiento de la relación que se establezca en los formularios que este rellene, o para atender una solicitud o consulta del mismo.
      </Paragraph>

      <Subtitle>Principios aplicables al tratamiento de los datos personales</Subtitle>
      <Paragraph>El tratamiento de los datos personales del Usuario se someterá a los siguientes principios:</Paragraph>
      <List>
        <li>Principio de licitud, lealtad y transparencia.</li>
        <li>Principio de limitación de la finalidad.</li>
        <li>Principio de minimización de datos.</li>
        <li>Principio de exactitud.</li>
        <li>Principio de limitación del plazo de conservación.</li>
        <li>Principio de integridad y confidencialidad.</li>
        <li>Principio de responsabilidad proactiva.</li>
      </List>

      <Subtitle>Categorías de datos personales</Subtitle>
      <Paragraph>Las categorías de datos que se tratan en VisionaryOps son únicamente datos identificativos. No se tratan categorías especiales de datos personales.</Paragraph>

      <Subtitle>Base legal para el tratamiento de los datos personales</Subtitle>
      <Paragraph>La base legal para el tratamiento de los datos personales es el consentimiento del Usuario, quien puede retirarlo en cualquier momento.</Paragraph>

      <Subtitle>Fines del tratamiento a que se destinan los datos personales</Subtitle>
      <Paragraph>
        Los datos personales son recabados y gestionados por VisionaryOps para cumplir con los compromisos establecidos entre el Sitio Web y el Usuario, así como para finalidades comerciales, estadísticas, y de personalización.
      </Paragraph>

      <Subtitle>Períodos de retención de los datos personales</Subtitle>
      <Paragraph>Los datos personales solo serán retenidos durante el tiempo necesario para los fines de su tratamiento, con un máximo de 24 meses, o hasta que el Usuario solicite su supresión.</Paragraph>

      <Subtitle>Destinatarios de los datos personales</Subtitle>
      <Paragraph>Los datos personales del Usuario no serán compartidos con terceros.</Paragraph>

      <Subtitle>Datos personales de menores de edad</Subtitle>
      <Paragraph>Solo los mayores de 14 años podrán otorgar su consentimiento para el tratamiento de sus datos personales por VisionaryOps.</Paragraph>

      <Subtitle>Secreto y seguridad de los datos personales</Subtitle>
      <Paragraph>
        VisionaryOps se compromete a adoptar las medidas técnicas y organizativas necesarias para garantizar la seguridad de los datos personales, aunque no puede garantizar la inexpugnabilidad de internet.
      </Paragraph>

      <Subtitle>Derechos derivados del tratamiento de los datos personales</Subtitle>
      <Paragraph>El Usuario podrá ejercer los siguientes derechos:</Paragraph>
      <List>
        <li>Derecho de acceso.</li>
        <li>Derecho de rectificación.</li>
        <li>Derecho de supresión ("el derecho al olvido").</li>
        <li>Derecho a la limitación del tratamiento.</li>
        <li>Derecho a la portabilidad de los datos.</li>
        <li>Derecho de oposición.</li>
        <li>Derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado.</li>
      </List>

      <Subtitle>Reclamaciones ante la autoridad de control</Subtitle>
      <Paragraph>En caso de que el Usuario considere que existe una infracción de la normativa vigente en la forma en que se están tratando sus datos personales, puede presentar una reclamación ante la Agencia Española de Protección de Datos.</Paragraph>

      <Subtitle>II. ACEPTACIÓN Y CAMBIOS EN ESTA POLÍTICA DE PRIVACIDAD</Subtitle>
      <Paragraph>Es necesario que el Usuario lea y acepte esta Política de Privacidad para el uso del Sitio Web.</Paragraph>
      <Paragraph>
        VisionaryOps se reserva el derecho a modificar su Política de Privacidad, de acuerdo a su propio criterio, o motivado por un cambio legislativo, jurisprudencial o doctrinal de la Agencia Española de Protección de Datos. Los cambios o actualizaciones de esta Política de Privacidad no serán notificados de forma explícita al Usuario. Se recomienda al Usuario consultar esta página de forma periódica para estar al tanto de los últimos cambios o actualizaciones.
      </Paragraph>

      <Paragraph>Esta Política de Privacidad fue actualizada para adaptarse al Reglamento (UE) 2016/679 y a la Ley Orgánica 3/2018 de Protección de Datos Personales.</Paragraph>
    </Container>
  );
};

export default PrivacyPolicy;
