import React, {useRef} from 'react';
import Hero from '../components/Hero';
import Benefits from '../components/Benefits';
import PainPoints from '../components/PainPoints';
import Services from '../components/Services';
import Solutions from '../components/Solutions';
import SuccessStories from '../components/SuccessStories';
import DiagnosticQuestionnaire from '../components/DiagnosticQuestionnaire';
import AboutUs from '../components/AboutUs';
import ProjectSteps from '../components/ProjectSteps';

const HomePage = () => {
  const questionnaireRef = useRef(null);

  const scrollToQuestionnaire = () => {
    questionnaireRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Hero scrollToQuestionnaire={scrollToQuestionnaire} />
      <Benefits />
      <Services scrollToQuestionnaire={scrollToQuestionnaire} />
      <PainPoints scrollToQuestionnaire={scrollToQuestionnaire} />
      <Solutions scrollToQuestionnaire={scrollToQuestionnaire} />
      <ProjectSteps />
      <SuccessStories />
      <div ref={questionnaireRef}>
        <DiagnosticQuestionnaire />
      </div>
      <AboutUs />
    </>
  );
};

export default HomePage;