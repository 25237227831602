import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faClipboardList, faRocket, faMagic } from '@fortawesome/free-solid-svg-icons';

gsap.registerPlugin(ScrollTrigger);

const ProjectStepsSection = styled.section`
  padding: 8rem 0;
  background: linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%);
  color: var(--white);
  overflow: hidden;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 4rem;
  color: var(--white);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Step = styled.div`
  flex: 1;
  min-width: 200px;
  text-align: center;
  padding: 2rem;
  position: relative;
  opacity: 0.3;
  transition: opacity 0.3s ease;
  
  &.active {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const StepIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: var(--white);
  transition: transform 0.3s ease;

  ${Step}.active & {
    transform: scale(1.2);
  }
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--white);
`;

const StepDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  opacity: 0.8;
`;

const ProgressLine = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 4px;
  background-color: var(--dark-accent);
  transform: translateY(-50%);
  transition: width 0.5s ease;
  z-index: 0;
  border-radius: 20px;

  @media (max-width: 768px) {
    top: 0;
    left: 50%;
    width: 4px;
    height: 0;
    transform: translateX(-50%);
    transition: height 0.5s ease;
  }
`;

const steps = [
  {
    icon: faStethoscope,
    title: "Diagnóstico",
    description: "Analizamos a fondo tu negocio para identificar áreas de mejora y oportunidades de crecimiento."
  },
  {
    icon: faClipboardList,
    title: "Plan",
    description: "Desarrollamos una estrategia personalizada basada en los hallazgos del diagnóstico."
  },
  {
    icon: faRocket,
    title: "Ejecución",
    description: "Implementamos las soluciones diseñadas, optimizando procesos y sistemas."
  },
  {
    icon: faMagic,
    title: "Transformación",
    description: "Observamos cómo tu negocio evoluciona, alcanzando nuevos niveles de eficiencia y éxito."
  }
];

const ProjectSteps = () => {
  const sectionRef = useRef(null);
  const stepsRef = useRef([]);
  const progressLineRef = useRef(null);

  useEffect(() => {
    const stepElements = stepsRef.current;
    const progressLine = progressLineRef.current;
    
    const animateSteps = () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top 70%',
          end: 'bottom 70%',
          toggleActions: 'play none none reverse'
        }
      });

      tl.to(progressLine, {
        width: '100%',
        height: '100%',
        duration: 2,
        ease: 'power2.inOut'
      });

      stepElements.forEach((step, index) => {
        tl.to(step, {
          opacity: 1,
          duration: 0.5,
          onStart: () => step.classList.add('active'),
          onReverseComplete: () => step.classList.remove('active')
        }, `-=${1.5}`);
      });

      return tl;
    };

    let animation;
    if (window.innerWidth > 768) {
      // For desktop, start animation automatically
      animation = animateSteps();
      animation.play();
    } else {
      // For mobile, trigger animation on scroll
      animation = animateSteps();
    }

    return () => {
      if (animation) animation.kill();
    };
  }, []);

  return (
    <ProjectStepsSection ref={sectionRef}>
      <Container>
        <Title>Nuestro Proceso de Transformación</Title>
        <StepsContainer>
          <ProgressLine ref={progressLineRef} />
          {steps.map((step, index) => (
            <Step key={index} ref={el => stepsRef.current[index] = el}>
              <StepIcon>
                <FontAwesomeIcon icon={step.icon} />
              </StepIcon>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Step>
          ))}
        </StepsContainer>
      </Container>
    </ProjectStepsSection>
  );
};

export default ProjectSteps;
