import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faChartLine, faCogs, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const SolutionsSection = styled.section`
  padding: 6rem 0;
  background: linear-gradient(135deg, var(--light-shade) 0%, var(--white) 100%);
  overflow: hidden;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--dark-shade);
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: var(--main-brand-color);
    margin: 1rem auto 0;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const SolutionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SolutionItem = styled.div`
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: var(--main-brand-color);
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;

  ${SolutionItem}:hover & {
    transform: scale(1.1);
  }
`;

const SolutionTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--dark-shade);
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: var(--main-brand-color);
    margin: 0.5rem auto 0;
    transition: width 0.3s ease;
  }

  ${SolutionItem}:hover &::after {
    width: 75px;
  }
`;

const SolutionDescription = styled.p`
  font-size: 1.1rem;
  color: var(--dark-shade);
  line-height: 1.6;
  position: relative;
  z-index: 1;
`;

const CtaButton = styled.button`
  font-size: 1.2rem;
  padding: 15px 30px;
  border-radius: 50px;
  background-color: var(--main-brand-color);
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 3rem auto 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: var(--dark-accent);
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
`;

const solutions = [
  {
    icon: faChartLine,
    title: "Mejora de ventas y atención al cliente",
    description: "Implementa estrategias y herramientas para impulsar tus ventas y mejorar la experiencia del cliente."
  },
  {
    icon: faChartPie,
    title: "Análisis de datos y KPI",
    description: "Toma decisiones informadas basadas en datos y métricas clave para tu negocio."
  },
  {
    icon: faCogs,
    title: "Optimización de herramientas CRM y ERP",
    description: "Maximiza el potencial de tus sistemas de gestión para una operación más eficiente."
  },
  {
    icon: faRobot,
    title: "Automatización de procesos internos",
    description: "Optimiza tus operaciones y reduce costos con soluciones de automatización inteligente."
  },
];

const Solutions = ({ scrollToQuestionnaire }) => {
  const solutionsRef = useRef(null);

  useEffect(() => {
    const solutionItems = solutionsRef.current.querySelectorAll('.solution-item');
    // gsap.from(solutionItems, {
    //   opacity: 0,
    //   y: 50,
    //   stagger: 0.2,
    //   duration: 0.8,
    //   ease: 'power3.out',
    //   scrollTrigger: {
    //     trigger: solutionsRef.current,
    //     start: 'top 80%',
    //   },
    // });
  }, []);

  return (
    <SolutionsSection ref={solutionsRef}>
      <SectionTitle>Soluciones que te ayudan a crecer</SectionTitle>
      <SolutionsGrid>
        {solutions.map((solution, index) => (
          <SolutionItem key={index} className="solution-item">
            <IconWrapper>
              <FontAwesomeIcon icon={solution.icon} />
            </IconWrapper>
            <SolutionTitle>{solution.title}</SolutionTitle>
            <SolutionDescription>{solution.description}</SolutionDescription>
          </SolutionItem>
        ))}
      </SolutionsGrid>
      <CtaButton onClick={scrollToQuestionnaire}>Descubre tu solución personalizada</CtaButton>
    </SolutionsSection>
  );
};

export default Solutions;
