import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import axios from 'axios';

const QuestionnaireContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, var(--light-shade) 0%, var(--white) 100%);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--dark-accent);
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: var(--main-brand-color);
    margin: 1rem auto 0;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--dark-shade);
  line-height: 1.6;
`;

const Form = styled.form`
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  padding: 2rem;
`;

const Question = styled(motion.div)`
  margin-bottom: 2rem;
`;

const QuestionText = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--dark-accent);
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 2px solid var(--light-accent);
  border-radius: 8px;
  color: var(--dark-shade);
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: var(--main-brand-color);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 2px solid var(--light-accent);
  border-radius: 8px;
  resize: vertical;
  color: var(--dark-shade);
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: var(--main-brand-color);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 2px solid var(--light-accent);
  border-radius: 8px;
  color: var(--dark-shade);
  background-color: var(--white);
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: var(--main-brand-color);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const Button = styled.button`
  font-size: 1.2rem;
  padding: 12px 24px;
  border-radius: 50px;
  background-color: var(--main-brand-color);
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--dark-accent);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: var(--light-accent);
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }

  svg {
    margin: 0 8px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledCheckbox = styled.div`
  width: 24px;
  height: 24px;
  background: ${props => (props.checked ? 'var(--main-brand-color)' : 'var(--white)')};
  border-radius: 5px;
  transition: all 150ms;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--light-accent);
  cursor: pointer;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px var(--light-accent);
  }

  svg {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
  font-size: 1rem;
  color: var(--dark-shade);
`;

const Checkbox = ({ className, checked, onChange, label, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
    <StyledCheckbox checked={checked} onClick={() => onChange({ target: { checked: !checked } })}>
      {checked && <FontAwesomeIcon icon={faCheck} color="white" />}
    </StyledCheckbox>
    <CheckboxLabel onClick={() => onChange({ target: { checked: !checked } })}>{label}</CheckboxLabel>
  </CheckboxContainer>
);

const ButtonMosaicContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const MosaicButton = styled.div`
  padding: 10px 20px;
  border: 2px solid var(--light-accent);
  background-color: ${(props) => (props.selected ? 'var(--main-brand-color)' : 'var(--white)')};
  color: ${(props) => (props.selected ? 'var(--white)' : 'var(--dark-accent)')};
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;

  &:hover {
    background-color: ${(props) => (props.selected ? 'var(--dark-accent)' : 'var(--light-accent)')};
    color: var(--white);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const MosaicCheckbox = ({ options, selectedValues, onChange }) => {
  const handleToggle = (option) => {
    if (selectedValues.includes(option)) {
      onChange(selectedValues.filter((item) => item !== option));
    } else {
      onChange([...selectedValues, option]);
    }
  };

  return (
    <ButtonMosaicContainer>
      {options.map((option) => (
        <MosaicButton
          key={option}
          selected={selectedValues.includes(option)}
          onClick={() => handleToggle(option)}
        >
          {option}
        </MosaicButton>
      ))}
    </ButtonMosaicContainer>
  );
};

const RadioGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 1rem;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledRadio = styled.div`
  width: 24px;
  height: 24px;
  background: ${(props) => (props.checked ? 'var(--main-brand-color)' : 'var(--white)')};
  border-radius: 50%;
  transition: all 150ms;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--light-accent);
  cursor: pointer;
  position: relative;

  ${HiddenRadio}:focus + & {
    box-shadow: 0 0 0 3px var(--light-accent);
  }

  &::before {
    content: '';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
  }
`;

const RadioLabel = styled.label`
  margin-left: 8px;
  font-size: 1rem;
  color: var(--dark-shade);
`;

const Radio = ({ className, checked, onChange, label, ...props }) => (
  <RadioContainer className={className}>
    <HiddenRadio checked={checked} onChange={onChange} {...props} />
    <StyledRadio checked={checked} onClick={() => onChange({ target: { checked: !checked } })} />
    <RadioLabel onClick={() => onChange({ target: { checked: !checked } })}>{label}</RadioLabel>
  </RadioContainer>
);

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: var(--light-accent);
  border-radius: 5px;
  margin-bottom: 2rem;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: var(--main-brand-color);
  transition: width 0.3s ease;
`;

const questions = [
  {
    id: 'company_name',
    text: '¿Cuál es el nombre de tu empresa?',
    type: 'text',
  },
  {
    id: 'web',
    text: '¿Cuál es la dirección de tu página web?',
    type: 'text',
  },
  {
    id: 'industry',
    text: '¿En qué industria opera tu empresa?',
    type: 'select',
    options: ['Tecnología', 'Salud', 'Educación', 'Finanzas', 'Retail', 'Otro'],
  },
  {
    id: 'employees',
    text: '¿Cuántos empleados tiene tu empresa?',
    type: 'select',
    options: ['1-10', '11-50', '51-200', '201-500', '501+'],
  },
  {
    id: 'current_tools',
    text: '¿Qué herramientas digitales utilizas actualmente en tu negocio?',
    type: 'mosaic',
    options: ['Comunicación y Colaboración', 'Marketing y Redes Sociales', 'Ventas y CRM', 'E-Commerce', 'Contabilidad y Finanzas', 'Análisis de datos y SEO', 'Pago y Facturación', 'Gestión de inventario', 'Atención al cliente', 'Productividad y Gestión de Documentos', 'Automatización de Procesos', 'Ciberseguridad', 'Gestión de Recursos Humanos'],
  },
  {
    id: 'areas_to_digitalize',
    text: '¿Qué áreas de tu empresa te gustaría digitalizar o automatizar?',
    type: 'select',
    options: ['Ventas', 'Atención al cliente', 'Gestión de inventario', 'Recursos Humanos', 'Análisis de datos', 'Operaciones de negocio', 'Otros']
  },
  {
    id: 'main_challenge',
    text: '¿Cuál es el principal desafío que enfrenta tu empresa en términos de digitalización?',
    type: 'select',
    options: ['Aumentar ventas', 'Mejorar procesos internos', 'Expandirse a nuevos mercados', 'Digitalizar operaciones', 'Resistencia al cambio organizacional', 'Falta de habilidades digitales', 'Costos de implementación', 'Ciberseguridad y privacidad de datos', 'Cambio en la cultura empresarial', 'Falta de estrategia digital clara', 'Adaptación de los procesos operativos', 'Medición del retorno de la inversión (ROI)', 'Otro'],
  },
  {
    id: 'main_goal',
    text: '¿Cuál es el principal objetivo de tu negocio este año?',
    type: 'select',
    options: ['Aumentar ventas / facturación', 'Mejorar procesos internos', 'Expandirse nuevos mercados', 'Digitalizar operaciones de negocio', 'Otro']
  },
  {
    id: 'strategic_direction',
    text: '¿Qué tan clara es la dirección estratégica de tu empresa en este momento?',
    type: 'scale',
    options: [1, 2, 3, 4, 5],
  },
  {
    id: 'online_sales',
    text: '¿Cómo vendes tus productos o servicios?',
    type: 'select',
    options: ['Venta online', 'Venta presencial', 'Venta por teléfono', 'Venta por suscripción', 'Venta al por mayor', 'Venta al por menor', 'Venta a través de Marketplaces', 'Venta automática (self-service)'],
  },
  {
    id: 'business_type',
    text: '¿Qué tipo de venta realizas?',
    type: 'select',
    options: ['B2B', 'B2C', 'B2B2C', 'Otro'],
  },
  {
    id: 'digital_tools_satisfaction',
    text: '¿Qué tan satisfecho estás con las herramientas digitales que utiliza actualmente tu empresa?',
    type: 'scale',
    options: [1, 2, 3, 4, 5],
  },
  {
    id: 'operational_problems_time',
    text: '¿Cuánto tiempo dedicas semanalmente a resolver problemas operativos?',
    type: 'select',
    options: ['Menos de 5 horas', '5-10 horas', 'Más de 10 horas'],
  },
  {
    id: 'automated_processes',
    text: '¿Tienes procesos internos automatizados?',
    type: 'select',
    options: ['Sí, algunos', 'No, todo es manual', 'No, pero nos interesa automatizar'],
  },
  {
    id: 'internal_processes_efficiency',
    text: '¿Qué tan eficientes consideras los procesos internos de tu empresa?',
    type: 'scale',
    options: [1, 2, 3, 4, 5],
  },
  {
    id: 'digital_sales_tracking',
    text: '¿Utilizas herramientas digitales para realizar seguimientos de ventas o clientes?',
    type: 'select',
    options: ['Sí, utilizamos software', 'No, todo es manual'],
  },
  {
    id: 'customer_retention_efficiency',
    text: '¿Qué tan eficiente es tu proceso actual para retener a tus clientes?',
    type: 'scale',
    options: [1, 2, 3, 4, 5],
  },
  {
    id: 'previous_digitalization_attempts',
    text: '¿Tu empresa ha intentado digitalizarse antes?',
    type: 'select',
    options: ['Sí, pero no obtuvimos resultados', 'Sí, y estamos satisfechos', 'No, pero queremos digitalizarnos'],
  },
  {
    id: 'digital_investment_concern',
    text: '¿Hay algo más que quieras añadir?',
    type: 'textarea',
  },
];

const DiagnosticQuestionnaire = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    current_tools: [],
  });
  const [otherValues, setOtherValues] = useState({});
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const validateCurrentStep = (currentFormData = formData) => {
    const currentQuestion = questions[currentStep];
    const value = currentFormData[currentQuestion.id];

    if (['text', 'textarea'].includes(currentQuestion.type)) {
      setIsNextDisabled(!value || value.trim() === '');
    } else if (currentQuestion.type === 'select') {
      if (value === 'Otro' || value === 'Otros') {
        const otherValue = otherValues[currentQuestion.id];
        setIsNextDisabled(!otherValue || otherValue.trim() === '');
      } else {
        setIsNextDisabled(!value);
      }
    } else if (currentQuestion.type === 'mosaic') {
      const selectedValues = currentFormData[currentQuestion.id] || [];
      setIsNextDisabled(selectedValues.length === 0);
    } else if (currentQuestion.type === 'scale') {
      setIsNextDisabled(value === undefined || value === null || value === '');
    } else {
      setIsNextDisabled(false);
    }
  };

  const handleInputChange = (value, questionId) => {
    setFormData(prevState => {
      const newState = { ...prevState, [questionId]: value };
      validateCurrentStep(newState);
      return newState;
    });

    if (value === 'Otro' || value === 'Otros') {
      setOtherValues(prevState => ({ ...prevState, [questionId]: '' }));
    } else {
      setOtherValues(prevState => {
        const { [questionId]: omit, ...rest } = prevState;
        return rest;
      });
    }
  };

  const handleNext = () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const selectedTools = formData.current_tools ? formData.current_tools.join(', ') : 'Ninguno';

    const finalFormData = { ...formData };
    Object.keys(otherValues).forEach((key) => {
      if (otherValues[key]) {
        finalFormData[key] = otherValues[key];
      }
    });

    const contactData = {
      email: finalFormData.email,
      attributes: {
        COMPANY_NAME: finalFormData.company_name,
        WEB: finalFormData.web,
        INDUSTRY: finalFormData.industry,
        EMPLOYEES: finalFormData.employees,
        CURRENT_TOOLS: selectedTools,
        MAIN_CHALLENGE: finalFormData.main_challenge,
        COMPANY_SIZE: finalFormData.company_size,
        MAIN_GOAL: finalFormData.main_goal,
        STRATEGIC_DIRECTION: finalFormData.strategic_direction,
        USING_CRM: finalFormData.using_crm,
        ONLINE_SALES: finalFormData.online_sales,
        DIGITAL_TOOLS_SATISFACTION: finalFormData.digital_tools_satisfaction,
        OPERATIONAL_PROBLEMS_TIME: finalFormData.operational_problems_time,
        AUTOMATED_PROCESSES: finalFormData.automated_processes,
        INTERNAL_PROCESSES_EFFICIENCY: finalFormData.internal_processes_efficiency,
        SALES_METHOD: finalFormData.sales_method,
        DIGITAL_SALES_TRACKING: finalFormData.digital_sales_tracking,
        CUSTOMER_RETENTION_EFFICIENCY: finalFormData.customer_retention_efficiency,
        PREVIOUS_DIGITALIZATION_ATTEMPTS: finalFormData.previous_digitalization_attempts,
      },
      listIds: [123],
      updateEnabled: true,
    };

    axios.post('https://api.brevo.com/v3/contacts', contactData, {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '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'
      }
    })
    .then((response) => {
      console.log('Contacto añadido/actualizado:', response.data);
      
      const emailData = {
        to: [{ email: 'hivisionaryops@gmail.com', name: 'Destinatario' }],
        templateId: 5,
        params: {
          COMPANY_NAME: finalFormData.company_name,
          WEB: finalFormData.web,
          INDUSTRY: finalFormData.industry,
          EMPLOYEES: finalFormData.employees,
          CURRENT_TOOLS: selectedTools,
          MAIN_CHALLENGE: finalFormData.main_challenge,
          COMPANY_SIZE: finalFormData.company_size,
          MAIN_GOAL: finalFormData.main_goal,
          STRATEGIC_DIRECTION: finalFormData.strategic_direction,
          USING_CRM: finalFormData.using_crm,
          ONLINE_SALES: finalFormData.online_sales,
          DIGITAL_TOOLS_SATISFACTION: finalFormData.digital_tools_satisfaction,
          OPERATIONAL_PROBLEMS_TIME: finalFormData.operational_problems_time,
          AUTOMATED_PROCESSES: finalFormData.automated_processes,
          INTERNAL_PROCESSES_EFFICIENCY: finalFormData.internal_processes_efficiency,
          SALES_METHOD: finalFormData.sales_method,
          DIGITAL_SALES_TRACKING: finalFormData.digital_sales_tracking,
          CUSTOMER_RETENTION_EFFICIENCY: finalFormData.customer_retention_efficiency,
          PREVIOUS_DIGITALIZATION_ATTEMPTS: finalFormData.previous_digitalization_attempts,
        }
      };
      
      axios.post('https://api.brevo.com/v3/smtp/email', emailData, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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'
        }
      })
      .then((response) => {
        console.log('Correo enviado:', response.data);
        Swal.fire({
          title: 'Gracias por completar el cuestionario',
          text: 'Te enviaremos tu diagnóstico personalizado pronto.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      })
      .catch((error) => {
        console.error('Error al enviar el correo:', error);
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al enviar el correo. Por favor, intenta nuevamente.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });
    })
    .catch((error) => {
      console.error('Error al enviar los datos a Brevo:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al enviar los datos. Por favor, intenta nuevamente.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    });
  };

  const renderInput = (question) => {
    switch (question.type) {
      case 'text':
        return <Input type="text" name={question.id} onChange={(e) => handleInputChange(e.target.value, question.id)} required />;
      case 'textarea':
        return <TextArea name={question.id} onChange={(e) => handleInputChange(e.target.value, question.id)} required />;
      case 'select':
        return (
          <>
            <Select name={question.id} onChange={(e) => handleInputChange(e.target.value, question.id)} required>
              <option value="">Selecciona una opción</option>
              {question.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {formData[question.id] === 'Otro' || formData[question.id] === 'Otros' ? (
              <Input
                type="text"
                placeholder="Especifica tu respuesta"
                value={otherValues[question.id] || ''}
                onChange={(e) => {
                  setOtherValues({ ...otherValues, [question.id]: e.target.value });
                  validateCurrentStep({ ...formData, [question.id]: e.target.value });
                }}
                style={{ marginTop: '1rem' }}  
                required
              />
            ) : null}
          </>
        );
      case 'scale':
        return (
          <RadioGroupContainer>
            {question.options.map((option) => (
              <Radio
                key={option}
                label={option}
                checked={formData[question.id] === option}
                onChange={() => handleInputChange(option, question.id)}
              />
            ))}
          </RadioGroupContainer>
        );
      case 'checkbox':
        return (
          <div>
            {question.options.map((option) => (
              <Checkbox
                key={option}
                name={question.id}
                value={option}
                label={option}
                checked={formData[question.id]?.includes(option) || false}
                onChange={(e) => {
                  const currentValues = formData[question.id] || [];
                  const newValue = e.target.checked
                    ? [...currentValues, option]
                    : currentValues.filter((item) => item !== option);
                  handleInputChange(newValue, question.id);
                }}
                required
              />
            ))}
          </div>
        );
      case 'mosaic':
        return (
          <MosaicCheckbox
            options={question.options}
            selectedValues={formData[question.id] || []}
            onChange={(updatedValues) => handleInputChange(updatedValues, question.id)}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    validateCurrentStep();
  }, [formData, currentStep]);

  return (
    <QuestionnaireContainer>
      <Title>Cuestionario de Diagnóstico</Title>
      <Description>
        Este diagnóstico te ayudará a entender en qué estado está tu empresa en términos de digitalización y qué pasos puedes dar para mejorar.
      </Description>    

      <Form onSubmit={handleSubmit}>
        <ProgressBar>
          <ProgressFill progress={(currentStep / questions.length) * 100} />
        </ProgressBar>
        
        <p style={{ textAlign: 'center', fontSize: '1.2rem', marginBottom: '1rem' }}>
          Pregunta {currentStep + 1} de {questions.length}
        </p>
        <AnimatePresence mode="wait">
          <Question
            key={currentStep}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <QuestionText>{questions[currentStep].text}</QuestionText>
            {renderInput(questions[currentStep])}
          </Question>
        </AnimatePresence>

        {currentStep === questions.length - 1 && (
          <>
            <Question>
              <QuestionText>¿Cuál es tu correo electrónico?</QuestionText>
              <Input
                type="email"
                name="email"
                onChange={(e) => handleInputChange(e.target.value, 'email')}
                required
              />
            </Question>
            <Question>
              <Checkbox
                name="privacy_policy"
                onChange={(e) => handleInputChange(e.target.checked, 'privacy_policy')}
                label="Acepto la política de privacidad"
                required
              />
            </Question>
          </>
        )}

        <ButtonContainer>
          <Button type="button" onClick={handlePrevious} disabled={currentStep === 0}>
            <FontAwesomeIcon icon={faChevronLeft} /> Anterior
          </Button>
          {currentStep === questions.length - 1 ? (
            <Button type="submit">Enviar <FontAwesomeIcon icon={faCheck} /></Button>
          ) : (
            <Button type="button" onClick={handleNext} disabled={isNextDisabled}>
              Siguiente <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          )}
        </ButtonContainer>
      </Form>
    </QuestionnaireContainer>
  );
};

export default DiagnosticQuestionnaire;
