import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${slideUp} 0.5s ease-out;
  z-index: 1000;
`;

const BannerContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const CookieIcon = styled.span`
  font-size: 24px;
  margin-right: 10px;
`;

const BannerText = styled.p`
  margin: 0;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const AcceptButton = styled(Button)`
  background-color: #4CAF50;
  color: white;
`;

const RejectButton = styled(Button)`
  background-color: #f44336;
  color: white;
`;

const CustomizeButton = styled(Button)`
  background-color: #2196F3;
  color: white;
`;

const PolicyLink = styled(Link)`
  color: #2196F3;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const CookieBanner = ({ onConsent }) => {
  const [showCustomize, setShowCustomize] = useState(false);

  const handleAccept = () => {
    onConsent('accept');
  };

  const handleReject = () => {
    onConsent('reject');
  };

  const handleCustomize = () => {
    setShowCustomize(!showCustomize);
  };

  const handleSavePreferences = () => {
    // Aquí normalmente guardarías las preferencias del usuario
    // Para este ejemplo, lo trataremos como "aceptar"
    onConsent('accept');
  };

  return (
    <BannerContainer>
      <BannerContent>
        <CookieIcon role="img" aria-label="Cookie">🍪</CookieIcon>
        <BannerText>
          Usamos cookies para mejorar tu experiencia. Al continuar visitando este sitio, aceptas nuestro uso de cookies.
          <PolicyLink to="/cookie-policy">Saber más</PolicyLink>
        </BannerText>
      </BannerContent>
      <ButtonContainer>
        <AcceptButton onClick={handleAccept}>Aceptar todas</AcceptButton>
        <RejectButton onClick={handleReject}>Rechazar todas</RejectButton>
        <CustomizeButton onClick={handleCustomize}>Personalizar</CustomizeButton>
      </ButtonContainer>
      {showCustomize && (
        <div>
          <h3>Personalizar preferencias de cookies</h3>
          <div>
            <input type="checkbox" id="essential" checked disabled />
            <label htmlFor="essential">Esenciales (Siempre activas)</label>
          </div>
          <div>
            <input type="checkbox" id="analytics" />
            <label htmlFor="analytics">Analíticas</label>
          </div>
          <div>
            <input type="checkbox" id="marketing" />
            <label htmlFor="marketing">Marketing</label>
          </div>
          <Button onClick={handleSavePreferences}>Guardar preferencias</Button>
        </div>
      )}
    </BannerContainer>
  );
};

export default CookieBanner;
