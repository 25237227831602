import React from 'react';
import styled from 'styled-components';
import DiagnosticQuestionnaire from '../components/DiagnosticQuestionnaire';

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--light-shade);
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const DiagnosticQuestionnairePage = () => {
  return (
    <PageContainer>
      <DiagnosticQuestionnaire />
    </PageContainer>
  );
};

export default DiagnosticQuestionnairePage;