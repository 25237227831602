import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const Subtitle = styled.h2`
  margin-top: 20px;
  color: #333;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHead = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const UnorderedList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const CookiePolicy = () => {
  return (
    <Container>
      <Title>Política de Cookies</Title>

      <Paragraph>
        El acceso a este Sitio Web puede implicar la utilización de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada Usuario —en los distintos dispositivos que pueda utilizar para navegar— para que el servidor recuerde cierta información que posteriormente y únicamente el servidor que la implementó leerá. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación.
      </Paragraph>

      <Paragraph>
        Las cookies son procedimientos automáticos de recogida de información relativa a las preferencias determinadas por el Usuario durante su visita al Sitio Web con el fin de reconocerlo como Usuario, y personalizar su experiencia y el uso del Sitio Web, y pueden también, por ejemplo, ayudar a identificar y resolver errores.
      </Paragraph>

      <Paragraph>
        La información recabada a través de las cookies puede incluir la fecha y hora de visitas al Sitio Web, las páginas visionadas, el tiempo que ha estado en el Sitio Web y los sitios visitados justo antes y después del mismo. Sin embargo, ninguna cookie permite que esta misma pueda contactarse con el número de teléfono del Usuario o con cualquier otro medio de contacto personal.
      </Paragraph>

      <Subtitle>Cookies propias</Subtitle>
      <Table>
        <thead>
          <tr>
            <TableHead>Función o característica</TableHead>
            <TableHead>Cookies y tecnología empleada</TableHead>
            <TableHead>Dominio Web</TableHead>
            <TableHead>Duración de almacenamiento</TableHead>
            <TableHead>Tipos de finalidad</TableHead>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>Mantener la sesión del usuario y flujo de navegación</TableCell>
            <TableCell>Cookie de sesión (sessionid)</TableCell>
            <TableCell>www.visionary-ops.com</TableCell>
            <TableCell>Mientras dure la navegación</TableCell>
            <TableCell>Cookies técnicas esenciales, Gestión de Sesión</TableCell>
          </tr>
          <tr>
            <TableCell>Garantizar que las solicitudes hechas al servidor sean seguras y originadas por el usuario legítimo</TableCell>
            <TableCell>Cookies temporales (csrftoken)</TableCell>
            <TableCell>www.visionary-ops.com</TableCell>
            <TableCell>1 año</TableCell>
            <TableCell>Cookies técnicas esenciales, Seguridad</TableCell>
          </tr>
        </tbody>
      </Table>

      <Subtitle>Cookies de terceros</Subtitle>
      <Paragraph>
        Son cookies utilizadas y gestionadas por entidades externas que proporcionan a VisionaryOps servicios solicitados para mejorar el Sitio Web y la experiencia del usuario. Los principales objetivos son la obtención de estadísticas de accesos y analizar la información de la navegación.
      </Paragraph>
      <Table>
        <thead>
          <tr>
            <TableHead>Función o característica (y quién la proporciona)</TableHead>
            <TableHead>Cookies y tecnología empleada</TableHead>
            <TableHead>Dominio Web</TableHead>
            <TableHead>Duración de almacenamiento</TableHead>
            <TableHead>Tipos de finalidad</TableHead>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>Google reCAPTCHA (Google, Inc.)</TableCell>
            <TableCell>
              Cookies temporales y persistentes (AEC; APISID; HSID; NID; SAPISID, etc.)
            </TableCell>
            <TableCell>www.google.es / www.google.com</TableCell>
            <TableCell>Años</TableCell>
            <TableCell>Cookies técnicas esenciales, Seguridad</TableCell>
          </tr>
          <tr>
            <TableCell>Google Analytics (Google, Inc.)</TableCell>
            <TableCell>
              Cookies temporales y persistentes (_ga; _gat; _gid; _utma; _utmc, etc.)
            </TableCell>
            <TableCell>www.visionary-ops.com / www.google.com</TableCell>
            <TableCell>Años</TableCell>
            <TableCell>Analíticas</TableCell>
          </tr>
        </tbody>
      </Table>

      <Paragraph>
        Puede obtener más información sobre las cookies en los siguientes enlaces:
      </Paragraph>
      <UnorderedList>
        <ListItem>
          <Link href="https://developers.google.com/analytics/">
            Google Analytics
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://tagmanager.google.com/">
            Google Tag Manager
          </Link>
        </ListItem>
      </UnorderedList>

      <Subtitle>Deshabilitar, rechazar y eliminar cookies</Subtitle>
      <Paragraph>
        El Usuario puede deshabilitar, rechazar y eliminar las cookies instaladas en su dispositivo mediante la configuración de su navegador. Los procedimientos para rechazar y eliminar las cookies pueden diferir de un navegador a otro. El Usuario debe acudir a las instrucciones del navegador que esté utilizando.
      </Paragraph>
    </Container>
  );
};

export default CookiePolicy;
