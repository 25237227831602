import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faCompass, faTools, faChartLine, faGlobe, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';

gsap.registerPlugin(ScrollTrigger);

const PainPointsSection = styled.section`
  padding: 6rem 2rem;
  background: linear-gradient(135deg, var(--light-shade) 0%, var(--main-brand-color) 100%);
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--white);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const PainPointsContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: translateX(-50%);

    @media (max-width: 768px) {
      left: 20px;
    }
  }
`;

const PainPointItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  color: var(--white);
  background-color: var(--main-brand-color);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  flex-shrink: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    position: absolute;
    left: 0;
    margin-right: 0;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    font-size: 1.8rem;
  }
`;

const PainPointContent = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PainPointTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--dark-accent);
`;

const PainPointDescription = styled.p`
  font-size: 1rem;
  color: var(--dark-shade);
  line-height: 1.6;
`;

const CtaButton = styled.button`
  font-size: 1.2rem;
  padding: 15px 30px;
  border-radius: 50px;
  background-color: var(--dark-accent);
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 5rem auto 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: var(--main-brand-color);
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
`;

const painPoints = [
  {
    icon: faFire,
    title: "Problemas operativos constantes",
    description: "Estás constantemente resolviendo problemas operativos y apagando incendios que no te permiten planificar ni definir una dirección clara para el futuro de tu negocio."
  },
  {
    icon: faCompass,
    title: "Falta de dirección clara",
    description: "Sientes que tu negocio carece de una dirección clara. Sabes que es necesario innovar y planificar estratégicamente, pero la falta de tiempo y el enfoque en resolver problemas cotidianos te impiden avanzar."
  },
  {
    icon: faTools,
    title: "Herramientas digitales ineficientes",
    description: "Las herramientas digitales que usas no están funcionando bien, están obsoletas o simplemente no se están utilizando de forma correcta."
  },
  {
    icon: faChartLine,
    title: "Retención de clientes y ventas ineficientes",
    description: "La retención de clientes y las ventas son ineficientes, pero no tienes el tiempo ni la estrategia para mejorar estos procesos."
  },
  {
    icon: faGlobe,
    title: "Falta de visibilidad online",
    description: "Buscas una mejor visibilidad online, pero no sabes cómo hacer que tu negocio destaque y aproveche las plataformas digitales correctamente."
  },
  {
    icon: faMoneyBillWave,
    title: "Preocupación por la inversión en digitalización",
    description: "Te preocupa hacer una inversión significativa en digitalización y no ver el retorno esperado."
  }
];

const PainPoints = ({ scrollToQuestionnaire }) => {
  const painPointsRef = useRef(null);
  const itemsRef = useRef([]);

  useEffect(() => {
    const items = itemsRef.current;
    
    if (window.innerWidth > 768) {
      items.forEach((item, index) => {
        gsap.to(item, {
          opacity: 1,
          duration: 0.5,
          scrollTrigger: {
            trigger: item,
            start: 'top center',
            end: 'bottom center',
            toggleClass: 'active',
            onEnter: () => item.classList.add('active'),
            onLeaveBack: () => item.classList.remove('active'),
          }
        });
      });
    } else {
      gsap.to(items, {
        opacity: 1,
        duration: 0.5,
        stagger: 0.2,
        scrollTrigger: {
          trigger: painPointsRef.current,
          start: 'top 80%',
        }
      });
    }
  }, []);

  return (
    <PainPointsSection ref={painPointsRef}>
      <SectionTitle>¿Te identificas con alguno de estos desafíos?</SectionTitle>
      <PainPointsContainer>
        {painPoints.map((point, index) => (
          <PainPointItem key={index} ref={el => itemsRef.current[index] = el}>
            <IconWrapper>
              <FontAwesomeIcon icon={point.icon} />
            </IconWrapper>
            <PainPointContent>
              <PainPointTitle>{point.title}</PainPointTitle>
              <PainPointDescription>{point.description}</PainPointDescription>
            </PainPointContent>
          </PainPointItem>
        ))}
      </PainPointsContainer>
      <CtaButton onClick={scrollToQuestionnaire}>Descubre cómo podemos ayudarte</CtaButton>
    </PainPointsSection>
  );
};

export default PainPoints;
