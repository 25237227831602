import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faLaptop, faCogs, faChartLine, faGraduationCap, faBullhorn, faRobot, faBrain } from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ServicesSection = styled.section`
  padding: 6rem 0;
  background: linear-gradient(135deg, var(--white) 0%, var(--light-shade) 100%);
  overflow: hidden;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--dark-shade);
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: var(--main-brand-color);
    margin: 1rem auto 0;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const ServiceItem = styled.div`
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--dark-shade);
  font-weight: bold;
  position: relative;
  z-index: 1;

  span {
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 3px;
      background-color: var(--main-brand-color);
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }
  }

  &:hover span::after {
    transform: scaleX(1);
  }
`;

const ServiceBrief = styled.p`
  font-size: 1.1rem;
  color: var(--dark-accent);
  margin-bottom: 1rem;
  font-weight: 500;
  position: relative;
  z-index: 1;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: var(--dark-shade);
  line-height: 1.6;
  position: relative;
  z-index: 1;
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: var(--main-brand-color);
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
`;

const AIServiceItem = styled(ServiceItem)`
  background: linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%);
  color: var(--white);

  ${ServiceTitle}, ${ServiceBrief}, ${ServiceDescription} {
    color: var(--white);
  }

  ${IconWrapper} {
    color: var(--white);
  }
`;



const services = [
  {
    icon: faUsers,
    title: <>Experiencia del <span>Cliente</span></>,
    brief: "Definición del público objetivo, análisis y fidelización.",
    description: "Personalizamos cada interacción para facilitar el viaje de compra y fomentar la lealtad, incrementando el valor a largo plazo."
  },
  {
    icon: faBrain,
    title: <><span>Consultoría en IA</span> y Automatización</>,
    brief: "Implementación de IA y optimización de procesos.",
    description: "Aprovechamos el poder de la IA para transformar tu negocio, automatizar tareas repetitivas y potenciar la toma de decisiones estratégicas.",
    isAI: true
  },
  {
    icon: faBullhorn,
    title: <><span>Marketing</span> y <span>Ventas</span></>,
    brief: "Estrategias digitales y embudos de venta.",
    description: "Desarrollamos estrategias completas, desde SEO hasta embudos de venta, para atraer y convertir clientes, fortaleciendo su presencia online."
  },
  {
    icon: faLaptop,
    title: <>Tecnología <span>Avanzada</span></>,
    brief: "Implementación de CRM, ERP y herramientas digitales.",
    description: "Integramos herramientas como CRM, chatbots y sistemas ERP para optimizar la comunicación y eficiencia de sus procesos internos y externos."
  },
  {
    icon: faChartLine,
    title: <><span>Crecimiento</span> Ágil</>,
    brief: "Metodologías ágiles y gestión de proyectos.",
    description: "Implementamos metodologías ágiles y PMOs para acelerar la adaptación y ejecución de proyectos, mejorando la respuesta en entornos cambiantes."
  },
  {
    icon: faGraduationCap,
    title: <><span>Formación</span> y <span>KPIs</span></>,
    brief: "Capacitación y monitoreo de indicadores clave.",
    description: "Ofrecemos formación especializada y establecemos KPIs para asegurar que su equipo esté alineado con los objetivos, impulsando decisiones informadas."
  },
];

const Services = () => {
  const servicesRef = useRef(null);

  useEffect(() => {
    const serviceItems = servicesRef.current.querySelectorAll('.service-item');
    // gsap.from(serviceItems, {
    //   opacity: 0,
    //   y: 50,
    //   stagger: 0.2,
    //   duration: 0.8,
    //   ease: 'power3.out',
    //   scrollTrigger: {
    //     trigger: servicesRef.current,
    //     start: 'top 80%',
    //   },
    // });
  }, []);

  return (
    <ServicesSection ref={servicesRef}>
      <SectionTitle>Nuestros Servicios</SectionTitle>
      <ServicesGrid>
        {services.map((service, index) => (
          service.isAI ? (
            <AIServiceItem key={index} className="service-item">
              <IconWrapper>
                <FontAwesomeIcon icon={service.icon} />
              </IconWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceBrief>{service.brief}</ServiceBrief>
              <ServiceDescription>{service.description}</ServiceDescription>
            </AIServiceItem>
          ) : (
            <ServiceItem key={index} className="service-item">
              <IconWrapper>
                <FontAwesomeIcon icon={service.icon} />
              </IconWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceBrief>{service.brief}</ServiceBrief>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceItem>
          )
        ))}
      </ServicesGrid>
    </ServicesSection>
  );
};

export default Services;
