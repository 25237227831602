import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FaLinkedin, FaInstagram, FaGlobe } from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

const SuccessStoriesSection = styled.section`
  padding: 6rem 0;
  background: linear-gradient(to bottom, var(--light-shade), #ffffff);
  overflow: hidden;

  .slick-slide {
    padding: 1rem;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: var(--main-brand-color);
  }

  .slick-dots li.slick-active button:before {
    color: var(--main-brand-color);
  }

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--dark-accent);
  font-weight: 700;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: var(--main-brand-color);
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const TestimonialCard = styled.div`
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  padding: 2.5rem;
  margin: 1rem;
  text-align: left;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:before {
    content: '"';
    position: absolute;
    top: 20px;
    left: 25px;
    font-size: 8rem;
    color: var(--main-brand-color);
    opacity: 0.1;
    font-family: Georgia, serif;
    line-height: 1;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const TestimonialContent = styled.div`
  position: relative;
  z-index: 1;
`;

const TestimonialText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--dark-shade);
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const AuthorSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const AuthorImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background-color: var(--light-shade);
  background-image: ${props => props.image ? `url(${props.image})` : 'none'};
  background-size: cover;
  background-position: center;
  border: 3px solid var(--main-brand-color);
`;

const AuthorInfo = styled.div`
  flex-grow: 1;
`;

const AuthorName = styled.h4`
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--dark-accent);
  margin: 0 0 0.5rem 0;
`;

const AuthorRole = styled.p`
  font-size: 0.9rem;
  color: var(--main-brand-color);
  margin: 0;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;

  a {
    color: var(--dark-accent);
    transition: color 0.3s ease;
    font-size: 1.2rem;

    &:hover {
      color: var(--main-brand-color);
    }
  }
`;

const testimonials = [
  {
    text: "Contactamos a VisionaryOps para que nos ayudaran con nuestra plataforma de venta y el concepto completo de INANAS SOAPS, nuestra marca de cosmética sólida. La experiencia fue impresionante: en la auditoría, nos mostraron todas las herramientas que usa nuestra competencia y nos dieron un mapa de acción para lograr mejor visibilidad e interacción. Nos impresionó ver cómo el equipo moldeó y adaptó su estrategia específicamente a nuestro estilo y necesidades, asegurándose de que cada ajuste fuera para potenciar nuestro crecimiento. Unos meses después, ¡los resultados ya están apareciendo y no podemos ser más felices!",
    author: "Andreea Enache",
    role: "Fundadora de iNANA's SOAPS",
    image: "/assets/testimonials/andreea.jpg",
    social: {
      instagram: "https://www.instagram.com/inanassoaps/",
      website: "https://inanassoaps.com/"
    }
  },
  {
    text: "VisionaryOps es una empresa muy profesional. Tanto Costin como Miller llevan su profesionalidad al máximo exponente. Su apoyo, explicación, análisis y propuesta de soluciones son de mucha ayuda a la hora de ponerlo en práctica. Nuestra experiencia con ellos ha sido muy satisfactoria.",
    author: "Oscar Martorell",
    role: "Puesto oficial de Informática Médica",
    image: "/assets/testimonials/oscar.jpg",
    social: {
      website: "https://informatica-medica.com/"
    }
  },
];

const SuccessStories = () => {
  const successStoriesRef = useRef(null);

  useEffect(() => {
    // gsap.from(successStoriesRef.current.querySelectorAll('.animate-on-scroll'), {
    //   opacity: 0,
    //   y: 50,
    //   stagger: 0.2,
    //   duration: 1,
    //   ease: 'power3.out',
    //   scrollTrigger: {
    //     trigger: successStoriesRef.current,
    //     start: 'top 80%',
    //   },
    // });
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <SuccessStoriesSection ref={successStoriesRef}>
      <SectionTitle className="animate-on-scroll">Otras empresas ya han transformado su negocio</SectionTitle>
      <Slider {...sliderSettings}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} className="animate-on-scroll">
            <TestimonialContent>
              <TestimonialText>{testimonial.text}</TestimonialText>
              <AuthorSection>
                {testimonial.image && (
                  <AuthorImage image={testimonial.image} />
                )}
                <AuthorInfo>
                  <AuthorName>{testimonial.author}</AuthorName>
                  <AuthorRole>{testimonial.role}</AuthorRole>
                  {testimonial.social && (
                    <SocialLinks>
                      {testimonial.social.linkedin && (
                        <a href={testimonial.social.linkedin} target="_blank" rel="noopener noreferrer">
                          <FaLinkedin />
                        </a>
                      )}
                      {testimonial.social.instagram && (
                        <a href={testimonial.social.instagram} target="_blank" rel="noopener noreferrer">
                          <FaInstagram />
                        </a>
                      )}
                      {testimonial.social.website && (
                        <a href={testimonial.social.website} target="_blank" rel="noopener noreferrer">
                          <FaGlobe />
                        </a>
                      )}
                    </SocialLinks>
                  )}
                </AuthorInfo>
              </AuthorSection>
            </TestimonialContent>
          </TestimonialCard>
        ))}
      </Slider>
    </SuccessStoriesSection>
  );
};

export default SuccessStories;
