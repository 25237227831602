import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';

const ContactContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--dark-accent);

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-size: 1.2rem;
  color: var(--dark-accent);
  text-decoration: none;

  &:hover {
    color: var(--main-brand-color);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Form = styled.form`
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--light-accent);
  border-radius: 5px;
  color: var(--dark-shade);
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--light-accent);
  border-radius: 5px;
  resize: vertical;
  color: var(--dark-shade);
  min-height: 100px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
  font-size: 1rem;
  color: var(--dark-shade);
`;

const Button = styled.button`
  font-size: 1.2rem;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--main-brand-color);
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--dark-accent);
  }

  &:disabled {
    background-color: var(--light-accent);
    cursor: not-allowed;
  }
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    website: '',
    message: '',
    privacyPolicy: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailData = {
      to: [{ email: 'hivisionaryops@gmail.com', name: 'Destinatario' }],
      templateId: 6, // Replace with the correct template ID for contact form
      params: {
        NAME: formData.name,
        COMPANY_NAME: formData.companyName,
        EMAIL: formData.email,
        WEBSITE: formData.website,
        MESSAGE: formData.message,
      },
    };

    axios.post('https://api.brevo.com/v3/smtp/email', emailData, {
      headers: {
        'Content-Type': 'application/json',
        'api-key': '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'
      }
    })
    .then((response) => {
      console.log('Email sent:', response.data);
      Swal.fire({
        title: 'Gracias por contactarnos',
        text: 'Nos pondremos en contacto contigo pronto.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      setFormData({
        name: '',
        companyName: '',
        email: '',
        website: '',
        message: '',
        privacyPolicy: false,
      });
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al enviar el mensaje. Por favor, intenta nuevamente.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    });
  };

  return (
    <ContactContainer>
      <Title>Contacto</Title>
      <ContactInfo>
        <ContactLink href="mailto:hivisionaryops@gmail.com">
          <FontAwesomeIcon icon={faEnvelope} />
          hivisionaryops@gmail.com
        </ContactLink>
        <ContactLink href="https://wa.me/34606234081" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} />
            +34 606 234 081
        </ContactLink>
        <ContactLink href="https://wa.me/34617103449" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} />
            +34 617 10 34 49
        </ContactLink>
      </ContactInfo>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">Nombre</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="companyName">Nombre de la empresa</Label>
          <Input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="website">Sitio web</Label>
          <Input
            type="url"
            id="website"
            name="website"
            value={formData.website}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="message">Mensaje</Label>
          <TextArea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="privacyPolicy"
            name="privacyPolicy"
            checked={formData.privacyPolicy}
            onChange={handleInputChange}
            required
          />
          <CheckboxLabel htmlFor="privacyPolicy">
            Acepto la política de privacidad
          </CheckboxLabel>
        </CheckboxContainer>
        <Button type="submit" disabled={!formData.privacyPolicy}>
          Enviar
        </Button>
      </Form>
    </ContactContainer>
  );
};

export default Contact;
