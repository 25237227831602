import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import styled from 'styled-components';
import Home from './pages/Home';
import DiagnosticQuestionnairePage from './pages/DiagnosticQuestionnaire';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact'; // Import the new Contact component
import CookieBanner from './components/CookieBanner';
import './styles/globals.css';

const NavBar = styled.nav`
  background-color: var(--dark-accent);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Logo = styled.img`
  height: 50px;
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 3px;
    width: 25px;
    background: var(--white);
    margin-bottom: 4px;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    align-items: center;
  }
`;

const NavItem = styled.li`
  margin: 0;
`;

const NavLink = styled(Link)`
  color: var(--white);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: var(--main-brand-color);
  }
`;

const Footer = styled.footer`
  background-color: var(--dark-accent);
  color: var(--white);
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
`;

const FooterLink = styled(Link)`
  color: var(--white);
  text-decoration: none;
  margin: 0 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const CookieArrow = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--dark-accent);
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  display: ${props => props.show ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Layout = ({ children }) => {
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [showCookieArrow, setShowCookieArrow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setShowCookieBanner(true);
    } else {
      setShowCookieArrow(true);
    }
  }, []);

  const handleCookieConsent = (consent) => {
    localStorage.setItem('cookieConsent', consent);
    setShowCookieBanner(false);
    setShowCookieArrow(true);
  };

  const handleShowCookieBanner = () => {
    setShowCookieBanner(true);
    setShowCookieArrow(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavBar>
        <Link to="/">
          <Logo src="/assets/logo2.png" alt="Logo de VisionaryOps" />
        </Link>
        <Hamburger onClick={toggleMenu}>
          <span />
          <span />
          <span />
        </Hamburger>
        <NavList isOpen={isOpen}>
          <NavItem>
            <NavLink to="/">Inicio</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/sobre-nosotros">Sobre Nosotros</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/cuestionario">Cuestionario de Diagnóstico</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/contacto">Contacto</NavLink>
          </NavItem>
        </NavList>
      </NavBar>
      {children}
      <Footer>
        © 2023 Tu Agencia de Marketing Digital. Todos los derechos reservados.
        <br />
        <FooterLink to="/privacy-policy">Política de Privacidad</FooterLink>
        <FooterLink to="/cookie-policy">Política de Cookies</FooterLink>
      </Footer>
      {showCookieBanner && <CookieBanner onConsent={handleCookieConsent} />}
      <CookieArrow show={showCookieArrow} onClick={handleShowCookieBanner}>
        🍪
      </CookieArrow>
    </>
  );
};

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre-nosotros" element={<AboutUs />} />
          <Route path="/cuestionario" element={<DiagnosticQuestionnairePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/contacto" element={<Contact />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
