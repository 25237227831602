import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AboutUsSection = styled.section`
  background-color: var(--white);
  padding: 6rem 0;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--dark-shade);
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 500px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  overflow: hidden;

  .description strong {
    color: var(--dark-shade);
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover .description {
    filter: blur(2px);
  }

  &:hover .description strong {
    filter: blur(0);
    color: var(--main-brand-color);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const Name = styled.h3`
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--dark-accent);
  margin-bottom: 1rem;
`;

const Description = styled.div`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: var(--dark-shade);
  transition: filter 0.3s ease;

  strong {
    color: var(--dark-shade);
    transition: color 0.3s ease, filter 0.3s ease;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const SocialLink = styled.a`
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 2.2rem;
  color: var(--dark-shade);
  transition: all 0.3s ease;

  ${SocialLink}:hover & {
    color: var(--main-brand-color);
  }
`;

const AboutUs = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const cards = sectionRef.current.querySelectorAll('.about-card');
    // gsap.from(cards, {
    //   opacity: 0,
    //   y: 50,
    //   stagger: 0.3,
    //   duration: 1,
    //   ease: 'power3.out',
    //   scrollTrigger: {
    //     trigger: sectionRef.current,
    //     start: 'top 70%',
    //   },
    // });
  }, []);

  return (
    <AboutUsSection ref={sectionRef}>
      <Container>
        <Title>Quiénes somos</Title>
        <CardWrapper>
          <Card className="about-card">
            <Image src="/assets/about-us/miller.jpg" alt="Miller" />
            <Name>Miller</Name>
            <Description className="description">
              ¡Hola! Soy Miller, especializado en combinar <strong>tecnología avanzada</strong> y <strong>estrategias de marca</strong> para ayudar a las empresas a crecer. Mi pasión es integrar <strong>automatización</strong> e <strong>inteligencia artificial</strong> en los procesos de negocio, optimizando tanto las <strong>operaciones clave</strong> como los <strong>embudos de ventas</strong>.
              <br /><br />
              Con experiencia en <strong>ingeniería robótica</strong> y herramientas de <strong>automatización</strong>, me centro en crear soluciones tecnológicas <strong>disruptivas</strong> y <strong>personalizadas</strong>. Junto a un enfoque creativo, diseño estrategias que <strong>maximizan la eficiencia</strong> y potencian el crecimiento de nuestros clientes.
            </Description>
            <SocialIcons>
              <SocialLink href="https://www.linkedin.com/in/miller-stiven-espinosa/" target="_blank" rel="noopener noreferrer">
                <Icon icon={faLinkedin} />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/millerespinosa_/" target="_blank" rel="noopener noreferrer">
                <Icon icon={faInstagram} />
              </SocialLink>
            </SocialIcons>
          </Card>
          <Card className="about-card">
            <Image src="/assets/about-us/costin.jpeg" alt="Costin" style={{ objectPosition: 'center top' }} />
            <Name>Costin</Name>
            <Description className="description">
              ¡Hola! Soy Costin, un apasionado de la tecnología y especializado en la <strong>digitalización y optimización de empresas</strong>. Durante mi carrera, he trabajado con diversos sectores ayudando a <strong>mejorar procesos internos</strong>, implementar <strong>herramientas tecnológicas</strong> y crear <strong>estrategias personalizadas</strong> que transforman negocios.
              <br /><br />
              Mi enfoque es encontrar <strong>soluciones prácticas y escalables</strong> para que la tecnología sea fácil de usar y aporte valor real a tu operación. Si buscas <strong>automatizar procesos, mejorar la eficiencia operativa</strong> o potenciar la <strong>retención de clientes</strong>, estaré encantado de colaborar contigo.
            </Description>
            <SocialIcons>
              <SocialLink href="https://www.linkedin.com/in/costinsoava/" target="_blank" rel="noopener noreferrer">
                <Icon icon={faLinkedin} />
              </SocialLink>
              <SocialLink href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <Icon icon={faInstagram} />
              </SocialLink>
            </SocialIcons>
          </Card>
        </CardWrapper>
      </Container>
    </AboutUsSection>
  );
};

export default AboutUs;
