import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';

const HeroSection = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  color: var(--white);
  background: url('/assets/hero-backgound.png') no-repeat center center/cover;
`;

const BackgroundVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: ${props => (props.$loaded ? '1' : '0')};
  transition: opacity 1s ease-in-out;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  max-width: 800px;
  width: 90%;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  color: var(--white);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 2rem;
  color: var(--light-shade);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.div`
  font-size: 1.2rem;
  max-width: 600px;
  margin: 20px auto;
  color: #fff;
  text-align: left;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    margin-bottom: 15px;
    font-size: 1.1rem;
    line-height: 1.5;
    position: relative;
    padding-left: 30px;

    &::before {
      content: '✅';
      position: absolute;
      left: 0;
      font-size: 1.2rem;
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CtaButton = styled.button`
  font-size: 1.2rem;
  padding: 15px 30px;
  border-radius: 50px;
  background-color: var(--main-brand-color);
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: var(--light-accent);
    transform: translateY(-3px) scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 1rem;
  }
`;

const Hero = ({ scrollToQuestionnaire }) => {
  const heroRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    // const tl = gsap.timeline();
    // tl.from(heroRef.current.querySelector('.content-wrapper'), {
    //   opacity: 0,
    //   y: 50,
    //   duration: 1,
    //   ease: 'power3.out'
    // });
    // tl.from(heroRef.current.querySelectorAll('.animate-item'), {
    //   opacity: 0,
    //   y: 20,
    //   stagger: 0.2,
    //   duration: 0.8,
    //   ease: 'power3.out'
    // }, "-=0.5");
  }, []);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  return (
    <HeroSection ref={heroRef}>
      <BackgroundVideo autoPlay loop muted onLoadedData={handleVideoLoaded} $loaded={videoLoaded}>
        <source src="/assets/hero-background.mp4" type="video/mp4" />
        <source src="/assets/hero-background.ogv" type="video/ogg" />
        <source src="/assets/hero-background2.webm" type="video/webm" />
        Your browser does not support the video tag.
      </BackgroundVideo>
      <ContentWrapper className="content-wrapper">
        <Title className="animate-item">¿Está tu negocio preparado para la era digital?</Title>
        <Subtitle className="animate-item">Descúbrelo con nuestro diagnóstico gratuito</Subtitle>
        <Description>
          <ul>
            <li className="animate-item">En solo 5 minutos</li>
            <li className="animate-item">Tiempo de respuesta 48 horas</li>
            <li className="animate-item">Presentación del diagnóstico</li>
          </ul>
        </Description>
        <CtaButton className="animate-item" onClick={scrollToQuestionnaire}>Comienza el diagnóstico</CtaButton>
      </ContentWrapper>
    </HeroSection>
  );
};

export default Hero;
